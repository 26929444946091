import React from 'react';
import styles from './CustomProgressBar.module.scss';

const Step = ({currentStep, totalSteps, partialProgress, stepsName, externalTickStep, index}: any) => {
    return (
        <div key={index} className={`${styles.step} ${index + 1 <= currentStep ? styles.completed : ''}`}>
            <div className={`${styles.step_number} ${index + 1 === currentStep ? styles.active : ''}`}>
                {index + 1 <= externalTickStep ? <>&#x2713;</> : index + 1}
                <div className={styles.label_name}>{stepsName[index]}</div>
            </div>
            {index < totalSteps - 1 && (
                <div className={`${styles.step_bar} ${index + 1 < currentStep ? styles.filled : ''}`}>
                    <div className={`${styles.step_bar_inner} ${index + 1 === currentStep ? styles.partially_filled : ''}`} style={{width: `${index + 1 === currentStep ? partialProgress * 100 : 100}%`}}></div>
                </div>
            )}
        </div>
    );
};

export default Step;
