import React, {useEffect, useMemo, useState} from 'react';
import {toast} from 'react-toastify';
import defaultAvatar from '../../../assets/images/al_default_avatar.png';
import defaultStyle from '../UserPublicProfileV2/UserPublicProfile.module.scss';
import desktopPreviewStyle from '../UserPublicProfile/UserPublicProfile_desktop_previewV2.module.scss';
import mobilePreviewStyle from '../UserPublicProfile/UserPublicProfile_mobile_previewV2.module.scss';
import {ReactComponent as LocationDynamic} from '../../../assets/images/al_location.svg';
import {ReactComponent as UserDynamic} from '../../../assets/images/Users.svg';
import {dynamicSocials} from '../../../constants/socialMedia';
import PublicProfileSectionCard from '../../../components/PublicProfileSectionCard/PublicProfileSctionCard';
import useWindowWidth from '../../../hooks/useWindowWidth';
import {
  getShapeData,
  NULL_THEME,
  previewStyles,
} from '../../../constants/newThemes';
import {getImagePath, isArabic, isRtl} from '../../../features/util';
import nonHookRequest from '../../../features/API/nonHookRequest';
import {numberFollowerFormat} from '../../../Helper/generalHelper';
import {useTranslation} from 'react-i18next';
const CleverTap = require('clevertap');
import ChargeCardModal from '../../../components/ChargeCardModal/ChargeCardModal';
import ChargeCardTapModal from '../../../components/ChargeCardTapModal/ChargeCardTapModal';
import {envConfig} from './../../../features/EnvironmentVariables/enviromentVariables';
import Tabs from '@material-ui/core/Tabs';
import {makeStyles} from '@material-ui/core/styles';
import AlbumDetails from '../../../components/MusicLinks/AlbumDetails';
import {MusicAlbumItem} from '../../../features/stores/musicAlbumsSlicer';
import {MusicLinkPreview} from '../../../components/MusicLinks/MusicLinkLineItem';
import {dealItem} from '../../../features/stores/dealsSlicer';
import PublicTab from './PublicTab';
import {ReactComponent as AlfanLogoRed} from '../../../assets/images/alfan-red-logo.svg';
import ct from 'countries-and-timezones';

interface IPublicUserProfile {
  linksUser: any;
  musicLinks: MusicAlbumItem[];
  deals: dealItem[];
  selectedStyle?: string;
  themeProp?: any;
  useOnlyLinksUser?: boolean;
}

// const clevertap = CleverTap.init('487-47Z-K46Z');

const UserPublicProfile = ({
  linksUser,
  musicLinks,
  deals,
  selectedStyle = 'default',
  themeProp,
}: IPublicUserProfile) => {
  const [openMonitizeModal, setOpenMonitizeModal] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [linkClickedID, setLinkClickedID] = useState('');
  const [monitizeLinkID, setMonitizeLinkID] = useState('');
  const [clientSecret, setClientSecret] = useState<any>('');
  const [monetizeObj, setMonetizeObj] = useState<any>({
    type: '',
    title: '',
    note: '',
    attachment: '',
    amount: [],
    url: '',
    maxPrice: 1000,
    minPrice: 1,
  });
  const [albumDetailsPopupVisibility, setAlbumDetailsPopupVisibility] =
    useState(false);
  const [selectedAlbum, setSelectedAlbum] = useState<MusicAlbumItem>();
  const [activePaymentMethod, setActivePaymentMethod] = useState(
      envConfig().activePaymentMethod || 'stripe',
  );
  const theme = themeProp === NULL_THEME ? linksUser : themeProp;
  const uniqueFollowerCount = linksUser?.displayFollower ?
    numberFollowerFormat(linksUser?.followersCount || 0, 1) :
    0;
  const followersNumber = linksUser?.followersCount;
  const mobile =
    selectedStyle === previewStyles.MOBILE_PREVIEW ? 760 : useWindowWidth();
  const displayLocale = linksUser?.displayLocale || 'en';
  const {t, i18n} = useTranslation();
  // const themeFont = isArabic(i18n.language) ? theme.arabicFont : theme.englishFont;
  const themeFont = theme.englishFont;
  const shapeData = getShapeData(theme.cardShape!);
  const nickname = linksUser.nickname;
  const copyLinkUrl = envConfig().copyLinkUrl;
  // the avatar ifologic needs to be changed for when the avatar becomes an URL instead of an object
  const profileAvatar = linksUser ?
    linksUser.avatar?.url || linksUser.avatar || defaultAvatar :
    theme.avatar || defaultAvatar;
  let backgroundImage = '';
  let socialMediaIconsStyle = {};
  let socialMediaIconsColor = '';
  let headerBorderRadius = '';
  let profileBoxTextColor = '';

  const translate = (key: string) => {
    return t(key, {lng: theme.displayLocale});
  };

  let styles = defaultStyle;
  headerBorderRadius = shapeData?.header.borderRadius!;
  if (selectedStyle === previewStyles.DESKTOP_PREVIEW) {
    styles = desktopPreviewStyle;
    backgroundImage = getImagePath({path: theme.desktopImage});
    socialMediaIconsStyle = {transform: 'scale(0.65)'};
    socialMediaIconsColor = theme.desktopSocialMediaColor;
    profileBoxTextColor = theme.nonCardTextColorDesktop;
  }
  if (selectedStyle === previewStyles.MOBILE_PREVIEW) {
    styles = mobilePreviewStyle;
    backgroundImage = getImagePath({path: theme.mobileImage});
    socialMediaIconsColor = theme.mobileSocialMediaColor;
    socialMediaIconsStyle = {transform: 'scale(0.75)'};
    profileBoxTextColor = theme.nonCardTextColor;
  }
  if (selectedStyle === previewStyles.DEFAULT) {
    styles = defaultStyle;
    if (mobile < 768) {
      backgroundImage = getImagePath({path: theme.mobileImage});
      socialMediaIconsColor = theme.mobileSocialMediaColor;
      profileBoxTextColor = theme.nonCardTextColor;
    } else {
      backgroundImage = getImagePath({path: theme.desktopImage});
      socialMediaIconsColor = theme.desktopSocialMediaColor;
      profileBoxTextColor = theme.nonCardTextColorDesktop;
    }
  }


  // // SECTIONS
  const userLinksSections = linksUser.linksSections;

  // // SOCIAL MEDIA SECTION
  const userSocialMediaLinks = userLinksSections
      .find((section: any) => section.type === 'social')
      ?.linksSectionItems?.filter((e: any) => e?.url)
      ?.slice()
      .sort((a: any, b: any) => a.orderNumber - b.orderNumber);

  const socials = dynamicSocials(socialMediaIconsColor);
  const socialMediaData = userSocialMediaLinks?.map(
      (item: any, index: number) => {
        const dynamicData = socials.filter((soc: any) => soc.key === item.type);
        if (dynamicData.length) {
          return (
            <div
              key={'social-icon-link' + index}
              style={{...socialMediaIconsStyle, cursor: 'pointer'}}
              className={styles.mediaIcons}
              onClick={() => {
                const regexp = new RegExp(item.type, 'gm');
                if (item.url.match(regexp) && item.url.match(regexp).length > 1) {
                  const final = item.url.substr(item.url.lastIndexOf('/') + 1);
                  item.url = 'https://' + item.type + '.com/' + final;
                }
                handleOpenLink(item.url, item.id);
              }}
            >
              {dynamicData[0].dynamic}
            </div>
          );
        }
      },
  );

  // // IMPORTANT LINKS SECTION
  const userImportantLinks = userLinksSections.find(
      (section: any) => section.type === 'important_links',
  );
  const visibleImportantLinks = userImportantLinks?.linksSectionItems.filter(
      (e: any) => e.isVisible,
  );

  const importantLinksData = visibleImportantLinks?.length > 0 && (
    <PublicProfileSectionCard
      theme={theme}
      id={userImportantLinks?.id}
      isVisible={userImportantLinks?.isVisible}
      linksSectionItems={userImportantLinks?.linksSectionItems.filter(
          (e: any) => e.isVisible,
      )}
      title={userImportantLinks?.title}
      subtitle={userImportantLinks?.subtitle}
      type={userImportantLinks?.type}
      selectedStyle={selectedStyle}
      linksUserId={linksUser.id}
      ownerUserId={linksUser?.userId}
      key={'important_links' + userImportantLinks?.id}
      nickname={nickname}
      email={linksUser?.publicEmail}
      setMonetizeID={setMonitizeLinkID}
      setMonetizeObj={setMonetizeObj}
      linkClickedID={linkClickedID}
      baseCopyUrl={`${copyLinkUrl}/${linksUser?.nickname?.toLowerCase()}`}
      maxDesktopContainerWidth={'100%'}
    />
  );

  const seasonalLinksSection = userLinksSections.find(
      (section: any) => section.type === 'seasonal',
  );
  const visibleSeasonalLinks = seasonalLinksSection?.linksSectionItems?.filter(
      (e: any) => e.isVisible,
  );

  const seasonalLinksData = visibleSeasonalLinks?.length > 0 && (
    <PublicProfileSectionCard
      theme={theme}
      id={seasonalLinksSection?.id}
      isVisible={seasonalLinksSection?.isVisible}
      linksSectionItems={visibleSeasonalLinks}
      title={seasonalLinksSection?.title}
      subtitle={seasonalLinksSection?.subtitle}
      type={seasonalLinksSection?.type}
      selectedStyle={selectedStyle}
      linksUserId={linksUser.id}
      ownerUserId={linksUser?.userId}
      key={'seasonal_links' + seasonalLinksSection?.id}
      nickname={nickname}
      email={linksUser?.publicEmail}
      setMonetizeID={setMonitizeLinkID}
      setMonetizeObj={setMonetizeObj}
      maxDesktopContainerWidth={'100%'}
    />
  );

  // // VIDEO LINKS SECTION
  const userVideoLinks = userLinksSections.find(
      (section: any) => section.type === 'video',
  );

  const videoData =
    // userVideoLinks.isVisible &&
    userVideoLinks?.linksSectionItems?.length !== 0 && (
      <PublicProfileSectionCard
        theme={theme}
        id={userVideoLinks?.id}
        isVisible={userVideoLinks?.isVisible}
        linksSectionItems={userVideoLinks?.linksSectionItems}
        title={userVideoLinks?.title}
        subtitle={'See the latest video'}
        type={userVideoLinks?.type}
        selectedStyle={selectedStyle}
        linksUserId={linksUser.id}
        key={'video' + userVideoLinks?.id}
        email={linksUser?.publicEmail}
        setMonetizeID={setMonitizeLinkID}
        enableSubtitle={true}
        maxDesktopContainerWidth={'100%'}
      />
    );

  const dealsData = deals.length !== 0 && (
    <div className={styles.dealsContainer}>
      <PublicProfileSectionCard
        theme={theme}
        id={userVideoLinks?.id}
        linksSectionItems={[]}
        title={''}
        type={'deals'}
        selectedStyle={selectedStyle}
        linksUserId={linksUser.id}
        isVisible={true}
        subtitle={''}
        setMonetizeID={setMonitizeLinkID}
        maxDesktopContainerWidth={'100%'}
        deals={deals}
      />
    </div>
  );

  const visibleMusicLinks = musicLinks.filter((link) => link.visible);
  const musicLinksData =
    visibleMusicLinks.length > 0 ? (
      <div className={styles.musicLinks}>
        {musicLinks.map((musicLinkItem: MusicAlbumItem) =>
          musicLinkItem.visible ? (
            <MusicLinkPreview
              theme={theme}
              parentStyles={styles}
              selectedStyle={selectedStyle}
              album={musicLinkItem}
              onClick={() => {
                if (selectedStyle === previewStyles.DEFAULT) {
                  setAlbumDetailsPopupVisibility(true);
                  setSelectedAlbum(musicLinkItem);
                }
              }}
              baseUrl={`${copyLinkUrl}/${linksUser?.nickname?.toLowerCase()}`}
              linkCopySuccessText={translate('copy_link_success')}
            />
          ) : (
            <></>
          ),
        )}
      </div>
    ) : undefined;

  const [selectedTab, setSelectedTab] = useState('links');

  const isBioLong = linksUser.bio && linksUser.bio.length > 128;

  const showShortenBio = () => isBioLong && mobile < 768;

  const handleClick = () => {
    // Toggle the showMore state when the description_wrapper is clicked
    setShowMore(!showMore);
  };

  useEffect(() => {
    setSelectedTab(
      visibleImportantLinks?.length > 0 ?
        'links' :
        musicLinksData ?
        'music' :
        userVideoLinks?.linksSectionItems?.length > 0 ?
        'videos' :
        'deals',
    );
  }, [
    visibleImportantLinks?.length,
    musicLinks,
    userVideoLinks?.linksSectionItems?.length,
  ]);

  const handleLinkCount = async (itemId?: any) => {
    if (linksUser && linksUser.userId && itemId) {
      await nonHookRequest({
        method: 'POST',
        url: '/linkAnalytics/count',
        body: {userId: linksUser.userId, linkId: itemId, nickname: nickname},
      });
    }
  };

  // FUNCTIONS
  const handleOpenLink = async (item: any, itemId?: any) => {
    if (!!item?.isMonitize) {
      if (selectedStyle === previewStyles.DEFAULT) {
        setMonitizeLinkID(itemId);
        handleLinkCount(itemId);
      } else {
        toast(
            <span style={{color: 'black'}}>
              {'Not active from this section!'}
            </span>,
            {autoClose: 3000},
        );
      }
      return;
    }
    if (item == 'redirect_to_landing_page') {
      window.location.href = `/?redirect_via=create_link&link_user_id=${linksUser?.userId}`;
    } else {
      window.open(item.includes('http') ? item : '//' + item, '_blank');
    }
    handleLinkCount(itemId);
  };

  const Footer = () => {
    return (
      <div className={styles.footer} onClick={() => handleOpenLink('redirect_to_landing_page')}>
        <span>{translate('powered_by')}</span>
        <AlfanLogoRed className={styles.footerLogo} />
      </div>
    );
  };

  const handleTabChange = (newValue: string) => {
    setLinkClickedID('');
    setSelectedTab(newValue);
  };


  const handlePaymentType = () => {
    const timezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
    const country = ct.getCountriesForTimezone(timezone);
    const notTapCountries = ['IL', 'PS'];
    if (country?.length && country?.some((e: any) => notTapCountries?.includes(e?.id))) {
      setActivePaymentMethod('stripe');
    }
  };

  const calculateJustify = useMemo(() => {
    let counter = 0;
    if (visibleMusicLinks?.length) {
      counter++;
    }
    if (userVideoLinks?.linksSectionItems?.length) {
      counter++;
    }
    if (visibleImportantLinks?.length) {
      counter++;
    }
    if (deals?.length) {
      counter++;
    }
    if (counter < 3) {
      return 'space-around';
    } else {
      return 'space-between';
    }
  }, [
    visibleMusicLinks,
    userVideoLinks?.linksSectionItems,
    visibleImportantLinks,
  ]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const clientSecretParam =
      urlParams.get('payment_intent_client_secret') || urlParams.get('tap_id');
    const bookUrl = urlParams.get('bookUrl');
    if (urlParams.get('tap_id') || urlParams.get('token') || bookUrl) {
      const q = window.location.search.slice(1, window.location.search.length);
      const b = q.split('&');
      let c = '';
      for (let i = 0; i < b.length; i++) {
        c =
          c +
          (b[i].includes('token') ||
          b[i].includes('tap_id') ||
          b[i].includes('bookUrl') ?
            '' :
            b[i] + '&');
      }
      const url = `${window.location.pathname}?${c}`;
      window.history.pushState({}, document.title, url);
    }
    if (clientSecretParam) {
      setMonetizeObj({
        ...monetizeObj,
        url: bookUrl || '',
        type: urlParams.get('linkType') == 'shortCall' ? 'shortCall' : '',
        contentType: urlParams.get('contentType') ?? null,
      });
      setClientSecret(clientSecretParam);
      setMonitizeLinkID('');
      setOpenMonitizeModal(true);
    }
    const linkParam = urlParams.get('link');
    if (linkParam) {
      setLinkClickedID(linkParam);
    }
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('music')) {
      const mlIndex = musicLinks.findIndex(
          (e) => e.urlSlug === urlParams.get('music'),
      );
      if (mlIndex > -1) {
        setAlbumDetailsPopupVisibility(true);
        setSelectedTab('music');
        setSelectedAlbum(musicLinks[mlIndex]);
      }
    }
  }, [musicLinks]);

  useEffect(() => {
    if (monitizeLinkID) {
      setClientSecret('');
      setOpenMonitizeModal(true);
    }
  }, [monitizeLinkID]);

  useEffect(() => {
    if (selectedStyle == 'default') {
      handlePaymentType();
    }
  }, [selectedStyle]);

  const useStyles = makeStyles({
    tabs: {
      '& .MuiTab-root': {
        minWidth: 'unset',
        flex: 1, // Make each tab take the whole width
        padding: [
          previewStyles.DESKTOP_PREVIEW,
          previewStyles.MOBILE_PREVIEW,
        ].includes(selectedStyle) ?
          '6px' :
          '6px 8px',
      },
      '& .MuiTab-wrapper': {
        flexDirection: 'row',
        justifyContent: 'flex-start',
      },
      '& .MuiTabs-indicator': {
        display: 'none',
      },
      '& .MuiTabs-flexContainer': {
        justifyContent: 'space-around', // Optional: You can set 'space-between' for spacing between tabs
        width: '100%',
        display: 'block',
      },
      '& .MuiTabs-scroller': {},
      '& .MuiTabs-scrollButtons': {
        width: '15px',
      },
      '& .MuiSvgIcon-root': {
        flexGrow: 1,
        width: [previewStyles.DESKTOP_PREVIEW].includes(selectedStyle) ?
          '1rem' :
          '2rem',
        height: [previewStyles.DESKTOP_PREVIEW].includes(selectedStyle) ?
          '1rem' :
          '2rem',
      },
    },
  });

  const classes = useStyles();

  const getContainerColor = (theme:any) => {
    if (mobile>=768) {
      return theme.cardColor;
    }
    return undefined;
  };

  return (
    <div
      dir={isRtl(theme.displayLocale) ? 'rtl' : 'ltr'}
      className={styles.main}
      style={{
        fontFamily: themeFont,
        backgroundColor: theme?.backgroundColor ?? undefined,
      }}
    >
      {openMonitizeModal && activePaymentMethod == 'stripe' ? (
        <ChargeCardModal
          linksUser={linksUser}
          theme={theme}
          monetizeObj={monetizeObj}
          monetizeID={monitizeLinkID}
          setCientSecret={setClientSecret}
          clientSecret={clientSecret}
          handleClose={() => {
            setOpenMonitizeModal(false);
            setMonitizeLinkID('');
            setMonetizeObj({
              type: '',
              title: '',
              note: '',
              attachment: '',
              url: '',
              maxPrice: 1000,
              minPrice: 1,
            });
          }}
        />
      ) : null}
      {openMonitizeModal && activePaymentMethod == 'tap' ? (
        <ChargeCardTapModal
          linksUser={linksUser}
          theme={theme}
          monetizeObj={monetizeObj}
          monetizeID={monitizeLinkID}
          clientSecret={clientSecret}
          userId={linksUser?.userId}
          handleClose={() => {
            setOpenMonitizeModal(false);
            setMonitizeLinkID('');
            setMonetizeObj({
              type: '',
              title: '',
              note: '',
              attachment: '',
              amount: [],
              url: '',
              maxPrice: 1000,
              minPrice: 1,
            });
          }}
        />
      ) : null}
      {albumDetailsPopupVisibility && (
        <AlbumDetails
          open={albumDetailsPopupVisibility}
          albumId={selectedAlbum?.id || ''}
          coverImageUrl={selectedAlbum?.coverImageUrl}
          audioUrl={selectedAlbum?.previewUrl}
          items={selectedAlbum?.albumLinks}
          playText={translate('play')}
          albumTitle={selectedAlbum?.title || ''}
          dir={isRtl(theme.displayLocale) ? 'rtl' : 'ltr'}
          albumDescription={selectedAlbum?.description || ''}
          onClose={() => {
            setAlbumDetailsPopupVisibility(false);
          }}
        />
      )}
      <div
        className={styles.container}
        style={
          profileAvatar || backgroundImage ?
            {
              backgroundColor: theme.backgroundColor!,
            } :
            {
              width: '100%',
              backgroundColor: theme.backgroundColor!,
            }
        }
      >
        <div
          className={styles.userDataContainer}
          style={
            profileAvatar || backgroundImage ?
              {backgroundColor: getContainerColor(theme), boxShadow: mobile>768?theme.boxShadow:undefined} :
              {backgroundColor: '#f7f7f8'}
          }
        >
          {profileAvatar && (
            <div
              className={styles.header}
              style={{
                ...(mobile < 768 && {marginBottom: 0}),
                borderRadius: headerBorderRadius,
                ...(socialMediaData &&
                  socialMediaData.length === 0 &&
                  mobile < 768 && {paddingBottom: 0}),
                border: '1px solid ' + theme.borderColor,
                ...(theme.themeName === '07' && {
                  borderRadius: 0,
                  border: '1px solid ' + theme.borderColor,
                }),
              }}
            >
              {/* Avatar start */}
              <div className={styles.user_wrapper}>
                <img className={styles.avatar} src={profileAvatar} />
                <div
                  className={styles.transition_div}
                  style={{
                    background:
                      'linear-gradient(180deg, rgba(255, 255, 255, 0.00)' +
                      (mobile > 768 ? '100%,' : '20%,') +
                      theme.backgroundColor! +
                      ' 100%)',
                  }}
                >
                  <div className={styles.username_userMeta_container}>
                    <div className={styles.user_meta}>
                      {!profileAvatar && (
                        <div className={styles.no_avatar_icons}>
                          {socialMediaData}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* Avatar end */}
            </div>
          )}

          <div className={styles.textContainer}>
            <span
              style={{
                color: profileBoxTextColor,
                fontFamily: themeFont,
              }}
              className={styles.username}
            >
              {linksUser.fullname}
            </span>

            {/* Bio start */}
            <div
              className={styles.description_wrapper}
              onClick={showShortenBio()? handleClick : undefined}
            >
              <span
                style={{
                  fontFamily: themeFont,
                  color: profileBoxTextColor,
                  cursor: showShortenBio() ? 'pointer' : 'auto', // Apply pointer cursor if bio is longer than 128 characters
                }}
              >
                {(showMore && linksUser.bio) || mobile > 768 ?
                  linksUser.bio || '' :
                  `${(linksUser.bio || '').substring(0, 128)}`}
              </span>
              {showShortenBio() && <>{showMore ? '' : <u>... </u>}</>}
            </div>

            {/* Bio end */}

            {/* Follower Count start */}
            <div className={styles.location_followers_container}>
              {uniqueFollowerCount !== 0 && followersNumber > 0 && (
                <div className={styles.follower_wrapper}>
                  <UserDynamic fill={profileBoxTextColor} />
                  <span
                    className={styles.follower}
                    style={{
                      color: profileBoxTextColor,
                      fontFamily: themeFont,
                    }}
                  >
                    {uniqueFollowerCount} {translate('Followers')}
                  </span>
                </div>
              )}
              {/* Follower Count end */}
              {/* Location start */}
              {linksUser.location || linksUser?.city ? (
                <div className={styles.location_container}>
                  <LocationDynamic fill={profileBoxTextColor} />
                  <span
                    className={styles.locations}
                    style={{
                      fontFamily: themeFont,
                      color: profileBoxTextColor,
                    }}
                  >
                    {`${
                      linksUser?.city ?
                        `${linksUser?.city}${linksUser?.location ? ',' : ''}` :
                        ''
                    } ${linksUser.location}`}
                  </span>
                </div>
              ) : (
                <div></div>
              )}
              {/* Location end */}
            </div>
            {/* Icons start */}
            <div className={styles.social_media_wrapper}>
              <div className={styles.media_scroll_handler}>
                {socialMediaData}
              </div>
            </div>
            {/* Icons end */}
          </div>
        </div>

        <div className={styles.tabsDiv}>
          {(userImportantLinks?.linksSectionItems?.length > 0 ||
            musicLinksData ||
            videoData ||
            dealsData) && (
            <Tabs
              className={`${styles.tabs} ${classes.tabs}`}
              style={{
                color: profileBoxTextColor,
                justifyContent: calculateJustify,
              }}
              value={selectedTab}
              // onChange={handleTabChange}
              // variant="standard"
              variant="scrollable"
              scrollButtons="off"
            >
              {visibleImportantLinks?.length > 0 && (
                <PublicTab
                  profileBoxTextColor={profileBoxTextColor}
                  styles={styles}
                  theme={theme}
                  label={translate('links')}
                  value={'links'}
                  selectedTab={selectedTab}
                  onClick={handleTabChange}
                  selectedStyle={selectedStyle}
                />
              )}
              {musicLinksData && (
                <PublicTab
                  profileBoxTextColor={profileBoxTextColor}
                  styles={styles}
                  theme={theme}
                  label={translate('music')}
                  value={'music'}
                  selectedTab={selectedTab}
                  onClick={handleTabChange}
                  selectedStyle={selectedStyle}
                />
              )}
              {videoData && (
                <PublicTab
                  profileBoxTextColor={profileBoxTextColor}
                  styles={styles}
                  theme={theme}
                  label={translate('videos')}
                  value={'videos'}
                  selectedTab={selectedTab}
                  onClick={handleTabChange}
                  selectedStyle={selectedStyle}
                />
              )}
              {dealsData && (
                <PublicTab
                  profileBoxTextColor={profileBoxTextColor}
                  styles={styles}
                  theme={theme}
                  label={translate('deals')}
                  value={'deals'}
                  selectedTab={selectedTab}
                  onClick={handleTabChange}
                  selectedStyle={selectedStyle}
                />
              )}
            </Tabs>
          )}
          {selectedTab === 'links' && (
            <>
              {seasonalLinksData}
              {importantLinksData}
            </>
          )}
          {selectedTab === 'music' && musicLinksData}
          {selectedTab === 'videos' && videoData}
          {selectedTab === 'deals' && dealsData}
        </div>
        {selectedStyle === previewStyles.DEFAULT && <Footer />}
      </div>
    </div>
  );
};

export default UserPublicProfile;
