import React, {useEffect, useState} from 'react';
// import logo from '../../assets/images/alfan-link-logo-design-blue.svg';
import logo from '../../assets/images/AlfanBrandNew.svg';
import classNames from 'classnames';
import useInput from '../../hooks/useInput';
import useRequest from '../../features/API/request';
import {raiseToast} from '../../components/Toast/Toast';
import {sendForgetPasswordCognito, createUserSession, removeUserSession} from '../../features/Login/login';
import TextFieldInput from '../../components/TextFieldInput/TextFieldInput';
import {useTranslation} from 'react-i18next';
import styles from './ForgetV2.module.scss';
import {Link} from 'react-router-dom';
import CustomProgressBar from '../../components/CustomProgressBar/CustomProgressBar';
import LanguagePicker from '../../components/LanguagePicker/LanguagePicker';

const cn = classNames.bind(styles);

const isEmail = (email: String) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const ForgetV2 = () => {
    const {
        value: emailValue,
        isValid: emailIsValid,
        hasError: emailHasError,
        valueChangeHandler: emailChangeHandler,
        inputBlurHandler: emailBlurHandler,
        reset: resetEmail,
        validate: validateEmail,
      } = useInput(isEmail);
      const {t} = useTranslation();
      const resetRequest = useRequest({url: '/auth/forgot', method: 'POST'});
      const handleClickButton = async () => {
        // validate email
        validateEmail();
        if (!emailIsValid) return;
        const result = await sendForgetPasswordCognito(emailValue.toLowerCase());
        if (result.error) {
          const splitData = result.message.split(':@');
          const msg = splitData.length > 1 ? splitData[splitData.length - 1] : '';
          switch (result.error) {
            case 'LimitExceededException':
              raiseToast({message: msg || result.message, ...(result.error && {variant: 'error'})});
              break;
            case 'UserNotFoundException': 
              raiseToast({message: msg || 'User not found.', ...(result.error && {variant: 'error'})});
              break;
            default:
              console.log('no match found');  
          }
          return;
        }
        raiseToast({message: 'Check your email!' || result.message, ...(result.error && {variant: 'error'})});
        createUserSession({username: emailValue});
        // window.location.assign('/public/reset-password');
      };
    
      const emailClasses = emailHasError ?
        cn(styles['form-group'], styles['invalid']) :
        styles['form-group'];
    
      useEffect(() => {
        removeUserSession();
      }, []);  

  return (
    <>
      <div className={styles.bg}>
        <Link className={styles.logo} to="/">
            <span>
              <img src={logo} alt="logo" style={{width: '93px'}} />
            </span>
        </Link>

        <div className={`${styles.main_body_container} ${styles.full_height}`}>
            <div className={styles.main_body_progress_box}>
                <CustomProgressBar hideProgress={true} completePercentage={0} currentStep={1} totalSteps={3} partialProgress={0.5} stepsName={[t('get_started'), t('about_you'), t('talent_zone')]} />
            </div>
            <div className={`${styles.main_body_form_box} ${styles.full_width} ${styles.center}`}>
                <div className={styles.language_container}>
                  <LanguagePicker onChange={() => {}} onBoardUi={true} />
                </div>
                <div className={styles.main_body_form_wrapper}>
                    <div>
                        <div>
                        <div className={`${styles.head_title} ${styles.text_center}`}>{t('reset_password')}</div>
                        <div className={`${styles.head_subtitle} ${styles.text_center} ${styles.mr_btm_40}`}>{t('enter_register_email')}</div>
                            <form onSubmit={(e)=>e.preventDefault()}>
                            <div className={emailClasses} >
                                <TextFieldInput 
                                    placeholder={t('email')}
                                    label={t('your_email')}
                                    staticHeader={true}
                                    customStyle={{padding: '27px 22px 10px'}}
                                    value={emailValue}
                                    onKeyUp={(e: any)=>{
                                    if (e.keyCode === 13) {
                                        handleClickButton();
                                    }
                                    }}
                                    onChange={(e: any) => {
                                    emailChangeHandler(e.target.value);
                                    }}
                                />
                                { emailHasError && <p className={styles['error-text']}>{t('invalid_email_error')}</p>}
                            </div>
                            <div className={styles['btn_wrapper']}>
                                <button
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleClickButton();
                                }}
                                className={styles['main-btn']}
                                >
                                {t('reset_instruction')}
                                </button>
                            </div>
                            </form>
                        </div>
                    </div>
                    <p className={styles['claim-copy-right']}>{t('claim_cr')}</p>
                </div>
            </div>
        </div>
      </div>
    </>
  );
};


export default ForgetV2;
