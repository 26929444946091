import React, {useState, useEffect} from 'react';
import {Auth} from 'aws-amplify';
import Loader from 'react-loader-spinner';
import Cookies from 'js-cookie';
import styles from './ConfirmEmailV2.module.scss';
import logo from '../../assets/images/AlfanBrandNew.svg';
import {raiseToast} from '../../components/Toast/Toast';
import {verifyEmailCognito, loginCognito, logIn, resendCognitoEmail, getUserSession} from '../../features/Login/login';
import {completeSignupTitkokEvent} from '../../Helper/generalHelper';
import TextFieldInput from '../../components/TextFieldInput/TextFieldInput';
import {useTranslation} from 'react-i18next';
import {sendEvent} from '../../features/util';
import {Link} from 'react-router-dom';
import CustomProgressBar from '../../components/CustomProgressBar/CustomProgressBar';
import LanguagePicker from '../../components/LanguagePicker/LanguagePicker';

const ConfirmEmailV2 = () => {
  const {t} = useTranslation();
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);

  const handleClevertapEvents = async (username: string, events: string[]) => {
    if (window?.clevertap) {
      window.clevertap.profile.push({
        'Site': {
          'Email': username,
        },
      });
      events.forEach((event) => {
        window.clevertap.event.push(event, {'UUID': username});
      });
    }
  };

  const handleEvent = async () => {
    const Trafficads = Cookies.get('Trafficads');
    const SignupSubmit = Cookies.get('signup_submit');
    const submitSignupForm = localStorage.getItem('submit_signup_form');
    const utmSource = Cookies.get('utm_source');

    if (submitSignupForm) {
      const userDecoded: any = await getUserSession();
      setTimeout(() => {
        if (userDecoded) {
          handleClevertapEvents(userDecoded.username, ['submit_signup_form']);
          localStorage.removeItem('submit_signup_form');
        }
      });
    }

    if (Trafficads) {
      setTimeout(() => {
        if (window?.fbq) {
          window.fbq('track', 'CompleteRegistration', {content_name: Trafficads, value: 0, currency: 'USD'});
          Cookies.remove('Trafficads');
        }
      });
    }

    if (SignupSubmit) {
      if (window.ttq && utmSource === 'tiktok') {
        completeSignupTitkokEvent();
      }
      sendEvent('signup_submit');
    }
  };


  const handleCognito = async () => {
    const tokens: any = await Auth.currentSession();
    if (tokens && tokens.idToken) {
      setLoading(true);
      localStorage.setItem('verify_email', '1');
      const me = await logIn({Bearer: tokens.idToken.jwtToken});
      if (me) {
        setCode('');
        if (!me.hasNickname) window.location.assign('/public/claim');
        else {
          localStorage.setItem('token', tokens.idToken.jwtToken);
          sessionStorage.clear();
          window.location.assign('/profile/home');
        }
      }
      setLoading(false);
    }
  };

  useEffect(()=>{
    handleEvent();
  }, []);

  const sendCode = async () => {
    try {
      setLoading(true);
      const storage = getUserSession();

      const result = await verifyEmailCognito(storage.username, code);
      if (!result) return;

      const loginResult = await loginCognito({email: storage.username, password: storage.creds});
      if (loginResult.error) return;

      setLoading(false);
      handleCognito();
      ['email_verified', 'signup_manual'].forEach((event) => sendEvent(event));
    } catch (error) {
      console.error('Error in sendCode:', error);
    } finally {
      setLoading(false);
    }
  };


  const resend = async ()=>{
    const storage = getUserSession();
    const result = await resendCognitoEmail(storage.username);
    raiseToast({message: 'Check your email! The new code should be there.'});
  };

  const codeClasses = styles['form-group'];

  return (
    <>
      {loading ? <div className={styles['globalLoader']}><Loader type="TailSpin" color="#EB3B5A" height={80} width={80} /></div> : null}
      <div className={styles.bg}>
        <Link className={styles.logo} to="/">
            <span>
              <img src={logo} alt="logo" style={{width: '93px'}} />
            </span>
        </Link>

        <div className={`${styles.main_body_container} ${styles.full_height}`}>
            <div className={styles.main_body_progress_box}>
                <CustomProgressBar completePercentage={0} currentStep={1} totalSteps={3} partialProgress={0.5} stepsName={[t('get_started'), t('about_you'), t('talent_zone')]} />
            </div>
            <div className={`${styles.main_body_form_box} ${styles.full_width} ${styles.center}`}>
                <div className={styles.language_container}>
                  <LanguagePicker onChange={() => {}} onBoardUi={true} />
                </div>
                <div className={styles.main_body_form_wrapper}>
                    <div>
                        <div>
                        <div className={`${styles.head_title} ${styles.text_center} ${styles.mr_btm_40}`}>{t('verify_your_email')}</div>
                          <form onSubmit={(e)=>e.preventDefault()}>
                                <div className={codeClasses} >
                                <TextFieldInput
                                    placeholder={t('confirmation_code')}
                                    label={t('confirmation_code')}
                                    staticHeader={true}
                                    customStyle={{padding: '27px 22px 10px'}}
                                    value={code}
                                    onKeyUp={(e: any)=>{
                                    if (e.keyCode === 13) {
                                        sendCode();
                                    }
                                    }}
                                    onChange={(e: any) => {
                                    setCode(e.target.value);
                                    }}
                                />
                                </div>
                                <div className={styles['btn_wrapper']}>
                                <button
                                    type="button"
                                    onClick={(e) => {
                                    sendCode();
                                    }}
                                    disabled={!code?.length}
                                    className={styles['main-btn']}
                                >
                                    {t('confirm_email_address')}
                                </button>
                                </div>

                                <div className={styles.resend_container}>
                                {t('not_received_code')} &nbsp;
                                <div className={styles.resend}
                                    onClick={resend}
                                > {t('resend')}</div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <p className={styles['claim-copy-right']}>{t('claim_cr')}</p>
                </div>
            </div>
        </div>
      </div>
    </>
  );
};


export default ConfirmEmailV2;
