/* eslint new-cap: ["error", { "capIsNewExceptionPattern": "^window\\.." }] */
import React, {useEffect} from 'react';
import {Redirect, Route, Switch} from 'react-router';
import ClaimNickname from '../../pages/ClaimNickname/claimNickname';
import ConfirmEmail from '../../pages/ConfirmEmail/ConfirmEmail';
import Forgot from '../../pages/Forgot/Forgot';
import Landing from '../../pages/Landing/landing';
import LandingPage from '../../pages/LandingPage/LandingPage';
import LandingPageNew from '../../pages/LandingPageNew/landingPageNew';
import Login from '../../pages/Login/login';
import NewPassword from '../../pages/NewPassword/NewPassword';
import ResetPassword from '../../pages/ResetPassword/ResetPassword';
import Signup from '../../pages/Signup/signup';
import {checkLogin} from './login';
import NativeLogin from '../../pages/NativeLogin/NativeLogin';
import SignupV2 from '../../pages/Signup/SignupV2';
import ConfirmEmailV2 from '../../pages/ConfirmEmail/ConfirmEmailV2';
import LoginV2 from '../../pages/Login/loginV2';
import ForgetV2 from '../../pages/Forgot/ForgetV2';

const PublicSwitch = () => {
  const publicRoutes = [{
    route: '/',
    component: Landing,
    exact: true,
  }, {
    route: '/public/login',
    component: LoginV2,
    exact: true,
  }, {
    route: '/public/signup',
    component: Signup,
    exact: true,
  }, {
    route: '/public/forgot',
    component: Forgot,
    exact: true,
  }, {
    route: '/public/reset/:token',
    component: NewPassword,
    exact: false,
  }, {
    route: '/public/confirm',
    component: ConfirmEmail,
    exact: true,
  }, {
  //   route: '/public/claim',
  //   component: ClaimNickname,
  //   exact: true,
  // }, {
    route: '/public/reset-password',
    component: ResetPassword,
    exact: true,
  }, {
    route: '/public/native-login',
    component: NativeLogin,
    exact: true,
  }];

  useEffect(()=>{
    const path = window.location.pathname;
    const match = publicRoutes.find((route)=>route.route === path ||
    route.route === '/public/reset/:token' && path.includes('/public/reset/'));
    if (!match && !checkLogin()) {
      window.location.assign('/');
    } else {
      // window.FreshworksWidget('hide', 'launcher');
    }
  }, [window.location.pathname]);


  return (
    <Switch>
      {/* <Route exact path="/" component={Landing} /> */}
      <Route exact path="/" component={LandingPageNew} />
      <Route exact path="/public/login" component={LoginV2} />
      <Route exact path="/public/signup" component={SignupV2} />
      <Route exact path="/public/forgot" component={ForgetV2} />
      <Route exact path="/public/reset-password" component={ResetPassword} />
      <Route path="/public/reset/:token" component={NewPassword} />
      <Route exact path='/public/confirm' component={ConfirmEmailV2} />
      {/* <Route exact path="/public/claim" component={ClaimNickname} /> */}
      <Route exact path="/public/native-login" component={NativeLogin} />
      <Route path='/*'>
        <Redirect to='/' />
      </Route>
      {/* <Route path='/*' component={Landing} /> */}
    </Switch>
  );
};

export default PublicSwitch;
