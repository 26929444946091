export const envConfig = (isShortUrl=false) => {
  const local = false; // change to false for remote backend URL
  const localVariables = {
    baseUrl: isShortUrl ? process.env.REACT_APP_BASE_SHORT_REMOTE_LOCAL : process.env.REACT_APP_BASE_URL_LOCAL,
    copyLinkUrl: process.env.REACT_APP_COPY_LINK_URL_LOCAL,
    youtubeApiKey: process.env.REACT_APP_YOUTUBE_API_KEY_LOCAL,
    gaViewId: process.env.REACT_APP_GA_VIEW_ID_LOCAL ? process.env.REACT_APP_GA_VIEW_ID_LOCAL : '',
    clevertTapId: process.env.REACT_APP_CLEVERTAP,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    cognitoOauthDomain: process.env.REACT_APP_COGNITO_OAUTH_DOMAIN,
    cognitoOauthSignin: process.env.REACT_APP_COGNITO_OAUTH_SIGNIN?.split(',')[1],
    cognitoOauthSignout: process.env.REACT_APP_COGNITO_OAUTH_SIGNOUT?.split(',')[1],
    stripeKey: process.env.REACT_APP_STRIPE_KEY,
    fbPixelId: process.env.REACT_APP_FACEBOOK_PIXEL_ID,
    tapPublicKey: process.env.REACT_APP_TAP_PUBLIC_KEY,
    activePaymentMethod: process.env.REACT_APP_ACTIVE_PAYMENT_METHOD,
    tapUsdMID: process.env.REACT_APP_TAP_USD_MID,
    tapAedMID: process.env.REACT_APP_TAP_AED_MID,
    tapSarMID: process.env.REACT_APP_TAP_SAR_MID,
    paypalAuthorizationUrl: process.env.REACT_APP_PAYPAL_AUTHORIZATION_URL,
    paypalClientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
    paypalRedirectUri: process.env.REACT_APP_PAYPAL_REDIRECT_URI,
    alfanGroupLink: process.env.REACT_APP_ALFAN_GROUP_LINK,
    trengoKey: process.env.REACT_APP_TRENGO_KEY,
  };

  const prodVariables = {
    baseUrl: isShortUrl ? process.env.REACT_APP_BASE_SHORT_REMOTE : process.env.REACT_APP_BASE_URL,
    copyLinkUrl: process.env.REACT_APP_COPY_LINK_URL,
    youtubeApiKey: process.env.REACT_APP_YOUTUBE_API_KEY,
    gaViewId: process.env.REACT_APP_GA_VIEW_ID ? process.env.REACT_APP_GA_VIEW_ID : '',
    clevertTapId: process.env.REACT_APP_CLEVERTAP,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    cognitoOauthDomain: process.env.REACT_APP_COGNITO_OAUTH_DOMAIN,
    cognitoOauthSignin: process.env.REACT_APP_COGNITO_OAUTH_SIGNIN?.split(',')[0], // change split 0 -> process.env.REACT_APP_COGNITO_OAUTH_SIGNIN?.split(',').length - 1 if want to use social login from localhost
    cognitoOauthSignout: process.env.REACT_APP_COGNITO_OAUTH_SIGNOUT?.split(',')[0], // change split 0 -> process.env.REACT_APP_COGNITO_OAUTH_SIGNOUT?.split(',').length - 1 if want to use social login from localhost
    stripeKey: process.env.REACT_APP_STRIPE_KEY,
    fbPixelId: process.env.REACT_APP_FACEBOOK_PIXEL_ID,
    tapPublicKey: process.env.REACT_APP_TAP_PUBLIC_KEY,
    activePaymentMethod: process.env.REACT_APP_ACTIVE_PAYMENT_METHOD,
    tapUsdMID: process.env.REACT_APP_TAP_USD_MID,
    tapAedMID: process.env.REACT_APP_TAP_AED_MID,
    tapSarMID: process.env.REACT_APP_TAP_SAR_MID,
    paypalAuthorizationUrl: process.env.REACT_APP_PAYPAL_AUTHORIZATION_URL,
    paypalClientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
    paypalRedirectUri: process.env.REACT_APP_PAYPAL_REDIRECT_URI,
    alfanGroupLink: process.env.REACT_APP_ALFAN_GROUP_LINK,
    trengoKey: process.env.REACT_APP_TRENGO_KEY,
  };
  return local ?
    localVariables :
    prodVariables;
};
