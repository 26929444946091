import React, {useState, useEffect, useMemo} from 'react';
import Cookies from 'js-cookie';
import useRequest, {useUploadRequest} from '../../features/API/request';
import {refreshMe, updateUserCognitoAttribute} from '../../features/Login/login';
import useInput from '../../hooks/useInput';
import {toast} from 'react-toastify';
import useWindowWidth from '../../hooks/useWindowWidth';
import {selectMe} from '../../features/stores/userSlicer';
import {useAppSelector} from '../../app/hooks';
import {store} from '../../app/store';
import {determineSafariCss, sendEvent, sendEventForClevertap} from '../../features/util';
import {decodeToken} from '../../features/Login/login';
import {changeBackground, completeSignupTitkokEvent, getSocialFilteredData, populateApiDataSocial} from '../../Helper/generalHelper';
import nonHookRequest from '../../features/API/nonHookRequest';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {selectLinksUser, setLinksUser} from '../../features/stores/linksUserSlicer';
import Loader from 'react-loader-spinner';
import styles from './ClaimNicknameV2.module.scss';
import logo from '../../assets/images/AlfanBrandNew.svg';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {Link, useHistory} from 'react-router-dom';
import CustomProgressBar from '../../components/CustomProgressBar/CustomProgressBar';
import AvatarDisplay from '../../components/AvatarDisplay/AvatarDisplay';
import SocialMediaLinks from '../../components/SocialMediaLinks/SocialMediaLinks';
import LanguagePicker from '../../components/LanguagePicker/LanguagePicker';
import {dataURLtoFile} from '../../Helper/imageCropper';

const isNotEmpty = (value: String) => value.trim() !== '';

const ClaimNicknameV2 = () => {
    const me = selectMe(useAppSelector(store.getState)).me;
    const user = useSelector(selectMe).me;
    const linksUser = useSelector(selectLinksUser);
    const [nicknameClaimed, setNicknameClaimed] = useState(false);
    const history = useHistory();
    const socialLinkAdded = useMemo(() => getSocialFilteredData(JSON.parse(JSON.stringify(linksUser?.linksSections)), true).filter((e: any) => e?.url?.length).length, [linksUser?.linksSections]);
    const {
      value: nicknameValue,
      isValid: nicknameIsValid,
      hasError: nicknameHasError,
      valueChangeHandler: nicknameChangeHandler,
      inputBlurHandler: nicknameBlurHandler,
      reset: resetNickname,
      validate: validateNickname,
    } = useInput(isNotEmpty, me?.linksUser?.nickname);
    const {t} = useTranslation();
    const formIsValid = nicknameIsValid && !nicknameHasError ? true : false;
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
  
    const updateProfileRequest = useRequest({method: 'POST', url: '/linksUser/profile-settings'});
    const request = useRequest({method: 'POST', url: '/user/nickname', session: true});
    const uploadAvatarRequest = useUploadRequest({method: 'POST', url: '/linksUser/user-avatar'});
    const uploadLinksRequest = useRequest({method: 'POST', url: '/linksSectionItem/section-items'});

    const screenWidth = useWindowWidth();

    const submitNickname = async () => {
      validateNickname();
      if (!formIsValid) {
        return;
      }
      const regex = new RegExp(/^[a-z0-9_.]{3,20}$/gm);
      if (!regex.test(nicknameValue)) {
        setError(t('username_regex_error'));
        return;
      }
  
      const result = await request.doRequest({nickname: nicknameValue});
      if (result) {
        toast(<span style={{color: 'black'}}>{t('username_claimed_successfully')}</span>, {autoClose: 9000});
        setNicknameClaimed(true);
        const userDecoded: any = await decodeToken();
        sendEvent('username_claimed');
        sendEventForClevertap('claim_username', {'UUID': userDecoded?.email || user?.email});
        const token = localStorage.getItem('token');
        if (!token) {
          const tokenFromSession = sessionStorage.getItem('token');
          if (tokenFromSession) {
            localStorage.setItem('token', tokenFromSession);
            sessionStorage.clear();
          }
        }
        handleRemainingSteps();
      } else {
        nicknameChangeHandler('');
        toast.warning(<span style={{color: 'black'}}>Username already taken.</span>);
      }
    };
  
    const claimUsernameStyle = ()=>{
      return {...determineSafariCss()};
    };
  
    const handleClevertapEvents = async (email: string, events: string[]) => {
      if (window?.clevertap) {
        window.clevertap.profile.push({
          'Site': {
            'Identity': user?.id || undefined,
            'Email': email || user?.email,
            'SocialLogin': user?.socialSignupProvider || undefined,
          },
        });
        events.forEach((event) => {
          window.clevertap.event.push(event, {'UUID': email});
        });
      }
    };
  
    const handleSocialSignup = async (provider: string, events: string[]) => {
      sendEvent('email_verified');
      sendEvent(`signup_${provider}`);
      const userDecoded: any = await decodeToken();
      setTimeout(() => {
        if (userDecoded) {
          handleClevertapEvents(userDecoded.email, events);
        }
      });
    };
  
    const handleEvent = async () => {
      const Trafficads = Cookies.get('Trafficads');
      const SignupSubmit = Cookies.get('signup_submit');
      const utmSource = Cookies.get('utm_source');
      const verifyEmail = localStorage.getItem('verify_email');
  
      if (verifyEmail) {
        const userDecoded: any = await decodeToken();
        setTimeout(() => {
          if (userDecoded) {
            handleClevertapEvents(userDecoded.email, ['verify_email']);
            localStorage.removeItem('verify_email');
          }
        });
      }
  
      if (Trafficads) {
        setTimeout(() => {
          if (window?.fbq) {
            window.fbq('track', 'CompleteRegistration', {content_name: Trafficads, value: 0, currency: 'USD'});
            Cookies.remove('Trafficads');
          }
        });
      }
  
      if (SignupSubmit && window.ttq && utmSource === 'tiktok') {
        completeSignupTitkokEvent();
      }
  
      if (user?.socialSignupProvider) {
        const provider = user.socialSignupProvider.toLowerCase();
        handleSocialSignup(provider, ['verify_email', `signup_${provider}`]);
      } else {
        handleSocialSignup('manual', ['verify_email', `signup_manual`]);
      }
    };
  
  
    const handleReferral = async () => {
      if (Cookies.get('referrer')) {
        updateUserCognitoAttribute({'custom:referredBy': Cookies.get('referrer')});
        nonHookRequest({method: 'POST', url: '/user/referralSignUpSocial', body: {referralCode: Cookies.get('referrer')}}).then((response) => {
          Cookies.remove('referrer');
        });
      }
    };
  
    const handleProfileEvent = (eventName: string) => {
      if (window.clevertap) {
        const data = [];
        if (linksUser?.categoryIds?.length && eventName == 'onboard_personal_info') {
          data.push('categoryIds');
        }
        if (linksUser?.contentCategoryIds?.length && eventName == 'onboard_personal_info') {
          data.push('contentCategoryIds');
        }
        if (data.length) {
          window.clevertap.event.push(eventName, {'data_type': data?.length ? data.join() : undefined, 'UUID': me.email});
        }
        window.location.href = '/profile/onboard';
      }
    };

    const handleRemainingSteps = async () => {
      setLoading(true);
      if (linksUser.avatar?.includes('base64')) {
        try {
          const file = dataURLtoFile(linksUser.avatar, `avatar-${linksUser.id}`);
          const params = {
            file: {
              key: 'avatar',
              value: file,
              fileName: linksUser.id,
            },
          };
          const result = await uploadAvatarRequest.doRequest(params);
          if (result) {
            toast(<span style={{color: 'black'}}>{t('avatar_updated')}</span>, {autoClose: 5000});
            sendEventForClevertap('onboard_avatar');
            sendEvent('avatar_confirm');
          }
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      }
      try {
          if (socialLinkAdded) {
            const populateApiData = populateApiDataSocial(linksUser);
            await uploadLinksRequest.doRequest(populateApiData?.data);
            const result = await refreshMe(true);
            if (result && populateApiData?.count > 0) {
              toast(<span style={{color: 'black'}}>{t('social_links_updated')}</span>, {autoClose: 5000});
              localStorage.setItem('onboard_social_links_added', '1');
              localStorage.setItem('social_icons_confirm', '1');
              history.push('/profile/onboard?restart=start');
            }
          } else {
            toast(<span style={{color: 'black'}}>{t('add_social_link')}</span>, {autoClose: 5000});
          }  
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
      setLoading(false);
    };

    const nextHandler = () => {
        if (!nicknameClaimed) {
          submitNickname();
        } else {
          handleRemainingSteps();
        }
    };
  
    const handleNext = async (removeData?: boolean) => {
      try {
        setLoading(true);
        const payload = {bio: linksUser?.bio, fullname: linksUser?.fullname, contentCategoryIds: linksUser?.contentCategoryIds || [], categoryIds: linksUser?.categoryIds || []};
        if (!removeData) {
          await updateProfileRequest.doRequest(payload);
          const result = await refreshMe(true);
          sendEvent('type_of_content_confirm');
          if (result) {
            handleProfileEvent('onboard_personal_info');
          }
        } else {
          store.dispatch(setLinksUser({...linksUser, bio: me?.linksUser?.bio, fullname: me?.linksUser?.fullname, contentCategoryIds: me?.userContentCategory?.map((e: {category: {id: string}}) => e?.category?.id) || [], categoryIds: me?.userCategory?.map((e: {category: {id: string}}) => e?.category?.id) || []}));
          window.location.href = '/profile/onboard';
          sendEvent('type_of_content_skip');
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
  
    useEffect(() => {
      if (me?.linksUser?.nickname) {
        setNicknameClaimed(true);
      }
    }, [me?.linksUser?.nickname]);
  
    useEffect(() => {
      handleEvent();
      handleReferral();
      changeBackground('#F4F0ED');
    }, []);

  return (
    <>
      {loading ? <div className={styles['globalLoader']}><Loader type="TailSpin" color="#EB3B5A" height={80} width={80} /></div> : null}
      <div className={styles.bg}>
        <Link className={styles.logo} to="/">
            <span>
              <img src={logo} alt="logo" style={{width: '93px'}} />
            </span>
        </Link>

        <div className={`${styles.main_body_container} ${styles.full_height}`}>
            <div className={styles.main_body_progress_box}>
                <CustomProgressBar completePercentage={30} currentStep={2} totalSteps={3} partialProgress={0} stepsName={[t('get_started'), t('about_you'), t('talent_zone')]} />
            </div>
            <div className={`${styles.main_body_form_box} ${styles.full_width} ${styles.center}`}>
                <div className={styles.language_container}>
                  <LanguagePicker onChange={() => {}} onBoardUi={true} />
                </div>
                <div className={styles.main_body_form_wrapper}>
                    <div>
                        <div>
                        <div className={`${styles.head_title} ${styles.text_center}`}>{t('time_to_shine')}</div>
                        <div className={`${styles.head_subtitle} ${styles.text_center} ${styles.mr_btm_40}`}>{t('upload_photo_and_share_your_socials')}</div>
                            <form onSubmit={(e)=>e.preventDefault()}>
                            <div className={styles.avatar_box}>
                                <AvatarDisplay onBoard={true} />
                            </div>
                            <div className={`${styles.inputContainer} ${nicknameClaimed ? styles.disabled : ''}`}>
                                <label className={styles.labelInInput}>alfan.link/</label>
                                <input
                                    id='username'
                                    name='username'
                                    className={styles.nicknameInput}
                                    style={{...claimUsernameStyle(), paddingLeft: '1px'}}
                                    type='text'
                                    value={nicknameValue || me?.linksUser?.nickname}
                                    onChange={(e) => {
                                    nicknameChangeHandler(e.target.value?.toLowerCase());
                                    if (error) setError('');
                                    }}
                                    placeholder={'username'}
                                    // required = {true}
                                    onBlur={nicknameBlurHandler}
                                    disabled={nicknameClaimed}
                                    onKeyPress={(e:any)=>{
                                    if (e.code === 'Enter') {
                                        e.preventDefault();
                                        submitNickname();
                                    }
                                    }}
                                />
                                {!!nicknameClaimed && <CheckCircleIcon style={{color: '#35CF60'}} />}
                                </div>
                                {error && <p className={styles['error-text']} style={{color: '#EF3124', display: 'flex'}}>{error}</p>}
                                {nicknameHasError && <p className={styles['error-text']} style={{color: '#EF3124', display: 'flex'}}>{t('enter_valid_username')}</p>}
                                <div className={styles.onboard_social_wrapper}>
                                    <div className={styles.social_header}>
                                        <div className={styles.social_title}>{t('drop_your_socials')}</div>
                                        <div className={styles.social_label}>{t('just_onefield_to_go')}</div>
                                    </div>
                                    <div className={styles.social_box}>
                                        <SocialMediaLinks />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
              <div className={styles['btn_wrapper']}>
                <button type="button" onClick={nextHandler} className={styles['main-btn']}>
                  {t('next')}
                </button>
              </div>
                    <p className={styles['claim-copy-right']}>{t('claim_cr')}</p>
                </div>
            </div>
        </div>
      </div>
    </>
  );
};


export default ClaimNicknameV2;
